@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
	--light-blue: #007bff;
	--light-green: #28a745;
	--light-red: #dc3545;
	--light-grey: #eee;
	--coal: #343a40;
  --toastify-color-progress-dark: #007bff;
}

@keyframes lifting-animation {
  0% {
    background-color: var(--light-blue);
    color: white;
  }
  50% {
    background-color: rgba(0, 0, 0, 0.05);
    color: var(--light-blue);
    font-size: 7rem;
  }
  100% {
    background-color: var(--light-blue);
    color: white;
  }
}

@keyframes resting-animation {
  0% {
    background-color: transparent;
    color: var(--light-green);
    outline: 1rem solid rgba(0, 0, 0, 0.05);
  }
  50% {
    color: var(--light-green);
    outline: 1rem solid var(--light-green);
  }
  100% {
    background-color: transparent;
    color: var(--light-green);
    outline: 1rem solid rgba(0, 0, 0, 0.05);
  }
}

#root {
	height: 100%;
}

*, *::before, *::after {
    box-sizing: inherit;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;

	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.link {
	color: var(--light-blue);
	font-weight: 700;
}

.link:hover {
	border-bottom: 1px solid var(--light-blue);
}

body {
	font-family: 'Poppins', sans-serif;
  font-size: 0.95rem;
}

html, body {
  overflow-x: hidden;
}

a {
	text-decoration: none;
}

p {
  	line-height: 1.7;
}

ul {
  	list-style: none;
}

li {
  	line-height: 2.2;
}

h1,
h2,
h3 {
	font-weight: 700;
	margin-bottom: 1rem;
}

h1 small,
h2 small,
h3 small {
	display: block;
  font-weight: initial;
}

hr {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 0;
  margin: 2rem 0;
}

.container {
	width: 100%;
	max-width: 450px;
	margin: 0 auto;
	padding: 0 1rem;
}

.header {
	background-color: var(--coal);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	padding: 1rem;
	-webkit-box-shadow: 0 10px 10px rgb(0 0 0 / 30%);
	-moz-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
	box-shadow: 0 10px 10px rgb(0 0 0 / 30%);
}

.headerMenu {
  display: flex;
	cursor: pointer;
}

.headerMenuIcon {
	color: white;
	font-size: 1.5rem;
	display: inline-block;
}

.headerMenuItems {
	display: none;
	position: fixed;
	z-index: 90;
	top: 3.5rem;
	left: 0;
	bottom: 0;
	width: 20rem;
	background-color: var(--light-grey);
}

.headerMenuItems.open {
	display: block;
}

.headerMenuItem {
	color: black;
}

.headerMenuItem a {
	display: block;
	color: inherit;
	padding: 1rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.headerMenuItem a:hover {
	background-color: #eee;
	/* color: white; */
}

.headerMenuItem.active a {
	background-color: var(--light-blue);
	color: white;
}

.headerMenuItem.active .headerMenuItemIcon {
	color: white;
}

.headerMenuItemIcon {
	color: #999999;
	font-size: 1rem;
	display: inline-block;
	vertical-align: middle;
	margin-right: 1rem;
}

.headerMenuItemLabel {
	font-weight: 600;
	font-size: 1rem;
}

.bodyWrapper {
	display: flex;
	margin-top: 80px;
	padding-bottom: 50px;
}

.btnGroup {
  display: flex;
  gap: 0.25rem;
  justify-content: flex-end;
}

.btnGroup * {
  /* width: 100%; */
}

.btn {
  white-space: nowrap;
	padding: 1rem 1.5rem;
	background-color: #ccc;
	color: black;
	border-radius: 0.5rem;
	font-weight: 600;
  font-size: inherit;
	border: none;
	cursor: pointer;
	line-height: 1;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.btn:hover {
	/* filter: brightness(70%); */
}

.btn.btn-small {
	padding: 0.5rem 1rem;;
  font-size: 0.75rem;
  line-height: 1;
}

.btn.btn-primary {
	background-color: var(--light-blue) !important;
	color: white;
}

.btn.btn-success {
	background-color: var(--light-green) !important;
	color: white;
}

.btn.btn-danger {
	background-color: var(--light-red) !important;
	color: white;
}

.btn.btn-disabled {
  opacity: 0.2;
	cursor: initial;
}

.btnIcon {
  font-size: 0.8rem;
}

.form {
	margin: auto;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
}

.formGroup {
  flex-basis: 100%;
  width: 100%;
  /* margin-bottom: 0.5rem; */
}

.formControl {
  border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 0.7rem 1rem;
  line-height: 1.5rem;
  font-size: inherit;
  font-family: inherit;
  border-radius: 0.5rem;
	width: 100%;
}

.form .btn {
  width: 100%;
}

select {
  /* appearance: none; Remove default browser styles */
  -webkit-appearance: none;
  cursor: pointer; /* Change cursor on hover */
  background-color: white; /* Set background color */
}

.table-responsive {
  overflow-x: scroll;
}

.table {
	width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
  font-size: 0.85rem;
  border: 1px solid #eee;
}

.table th {
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: 600;
	padding: 0.5rem;
  vertical-align: middle;
  text-align: inherit;
  border: 1px solid white;
}

.table td {
  background-color: rgba(0, 0, 0, 0.05);
	padding: 0.5rem;
  vertical-align: middle;
  text-align: inherit;
  border: 1px solid white;
  /* vertical-align: top; */
  line-height: 1.125rem;
}

.table .btn {
  padding: 0.5rem;
}

.table small {
  font-size: 0.7rem;
  display: block;
  color: rgba(0, 0, 0, 0.5);
}

.badge {
	display: inline-block;
  padding: 0 0.5rem;
  font-size: 0.5rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 0.5rem;
  color: white;
	margin-left: 0.5rem;
	padding: 0.5rem;
	line-height: 1;
}

.badge.badge-success {
	background-color: var(--light-green);
}

.badge.badge-danger {
	background-color: var(--light-red);
}

.badge.badge-normal {
	background-color: var(--light-grey);
}

.badge.badge-info {
	background-color: var(--light-blue);
}

.badge.badge-large {
  font-size: 0.75rem;
	font-weight: 600;
}

.pageTitle {
	font-size: 2rem;
	line-height: 1;
	font-weight: 700;
	/* text-transform: uppercase; */
	letter-spacing: 0;
	text-align: center;
	margin: 1rem 0;
  /* padding-bottom: 1rem; */
  /* border-bottom: 2px solid rgba(0, 0, 0, 0.1); */
}


.session-wrapper {
  width: 100%;
  text-align: center;
  opacity: 0.5;
  margin: 1rem 0 0;
}

.session-wrapper.current {
  /* background-color: red; */
  opacity: 1;
}

.session-wrapper .session-exercise-name {
  margin: 0 0 0.5rem;
}

.session-wrapper .session-exercise-name span {
  display: block;
}

.session-wrapper .session-exercise-name span:first-of-type {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 0.6rem;
  letter-spacing: 0.15rem;
  color: rgba(0, 0, 0, 0.75);
}

.session-wrapper .session-exercise-name span:last-of-type {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
}

.session-wrapper .session-attributes {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin: 0 0 2rem;
}

.session-wrapper .session-attributes .session-attribute {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.session-wrapper .session-attributes .session-attribute:first-of-type {
  border-left: none;
}

.session-wrapper .session-attributes .session-attribute span {
  display: block;
}

.session-wrapper .session-attributes .session-attribute span:first-of-type {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 0.8rem;
  letter-spacing: 0.15rem;
  color: rgba(0, 0, 0, 0.5);
}

.session-wrapper .session-attributes .session-attribute span:last-of-type {
  font-size: 1.125rem;
  font-weight: 600;
}

.session-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 2rem;
}

.session-counter.lifting > div {
  animation: lifting-animation 2s infinite;
}

.session-counter.resting > div {
  animation: resting-animation 4s infinite;
}

.session-counter > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  text-align: center;
  font-weight: 700;
  outline: 1rem solid rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  width: 12rem;
  height: 12rem;
  line-height: 1;
  color: rgba(0, 0, 0, 0.4);
  /* animation: resting-animation 4s infinite; */
}

.session-counter > div span:last-of-type {
  font-size: 1rem;
  text-transform: uppercase;
}

/* .session-wrapper .btn {
  width: 100%;
} */

.session-controls {
  display: flex;
  gap: 0.5rem;
  /* margin: 1rem 0 0; */
  justify-content: space-evenly;
  width: 100%;
}

.session-controls .btn {
  flex-basis: 50%;
  width: 100%;
  margin-bottom: 0.5rem;
  flex: 1;
}


.div-table {
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.div-table-row {
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  width: 100%;
  display: flex;
  /* flex-wrap: nowrap; */
  flex-direction: row;
  justify-content: flex-start;
}

.div-table-thead, .div-table-th, .div-table-td {
  /* display: flex; */
  padding: 0.5rem 1rem;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  flex-basis: 25%;
}

.div-table-thead {
  font-weight: 700;
  font-size: 1rem;
  flex: 1;
  margin-top: 2rem;
}

.div-table-th {
  font-weight: 700;
}

.div-table-th, .div-table-td {
  flex-basis: 25%;
  font-size: 0.85rem;
}
